<template>
  <div class="register">
    <div class="bar">
      <div @click="onClickLeft" class="box"><van-icon color="#fff" size="20" class="return" name="arrow-left" /></div>
      <div class="user">{{$t('USERREGISTER')}}</div>
    </div>
    <van-form class="formt" @submit="onSubmit">
      <van-field
        v-model="phoneNum"
        name="phoneNum"
        :placeholder="$t('INPUTPHONE')"
      >
        <div slot="label" class="whitecolor font15">
          <!-- {{$t('CONTACTPHONE')}}<span class="prefix">+66</span> -->
          <div slot="label" class="whitecolor">{{$t('CONTACTPHONE')}}</div>
        </div>
      </van-field>
      <van-field
        v-model="username"
        name="username"
        :placeholder="$t('USERNAME')"
      >
        <div slot="label" class="whitecolor">{{$t('USERNAME')}}</div>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        name="password"
        :placeholder="$t('PASSWORD')"
      >
        <div slot="label" class="whitecolor">{{$t('PASSWORD')}}</div>
      </van-field>
      <van-field
        v-model="checkpassword"
        type="password"
        name="checkpassword"
        :placeholder="$t('CHECKPASSWORDRe')"
      >
        <div slot="label" class="whitecolor">{{$t('CHECKPASSWORDRe')}}</div>
      </van-field>
      <!-- <van-field
        v-model="sms"
        name="message"
        :placeholder="$t('INPUTNOTE')"
      >
        <template #button>
          <van-button v-if="empty" @click="sendAuthCode" native-type="button" class="yanzhengma" size="small" type="warning">{{$t('SEND_SMS')}}</van-button>
          <van-count-down @finish="changeEmpty" ref="countDown" v-else :time="time">
            <template #default="timeData">
              <span class="block">{{timeData.seconds}}</span>
            </template>
          </van-count-down>
        </template>
        <div slot="label" class="whitecolor">{{$t('NOTE')}}</div>
      </van-field> -->
      <div class="subbox">
        <van-button class="sub" round block type="default" native-type="submit">{{$t('REGISTER')}}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { register, send } from '@/api/login'
import './css/index.css'
export default {
  name: 'register',
  data () {
    return {
      username: '',
      password: '',
      sms: '',
      empty: true,
      time: 6 * 10000,
      phoneNum: '',
      checkpassword: ''
    }
  },
  methods: {
    async onSubmit (values) {
      // values.message &&
      if (values.password && values.phoneNum && values.username && !(/[\u4E00-\u9FA5]/g.test(values.username))) {
        // 调用接口
        if (values.password !== this.checkpassword) {
          Toast(this.$t('TWICE'))
          return false
        }
        const { data } = await register({
          // captcha: values.message,
          name: values.username,
          password: values.password,
          phone: values.phoneNum,
          agent: document.domain
        })
        console.log(data)
        // 成功
        if (data.code === 200) {
          this.$store.commit('setToken', data.data)
          Toast(this.$t('REGISTEROK'))
          // this.$router.push({
          //   name: 'login'
          // })
          // Toast.loading({
          //   message: this.$t('AWAIT'),
          //   forbidClick: true,
          //   loadingType: 'spinner'
          // })
          setTimeout(() => {
            this.$router.push('/my/mydata')
          }, 3000)
        }
        if (data.code === 500) {
          Toast(data.message)
        }
      } else {
        Toast(this.$t('NOTNULL'))
      }
      console.log('submit', values)
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    async sendAuthCode () {
      if (!this.phoneNum) {
        Toast(this.$t('NOTNULL'))
        return false
      }
      if (this.phoneNum.length < 10) {
        Toast(this.$t('LENGTH10'))
        return false
      }
      // console.log(66 + this.phoneNum)
      const { data } = await send({
        phone: this.phoneNum,
        type: 1
      })
      console.log(data)
      console.log('验证码')
      this.empty = false
    },
    changeEmpty () {
      this.empty = true
    },
    getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    }
  },
  created () {
  }
}
</script>

<style scoped>
.font15 {
  font-size: 14px!important;
}
.block {
  width: 60px !important;
  height: 31.98px;
  display: block;
  width: 22px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background-color: #ff976a;
  line-height: 30px;
}
.prefix {
  color: red;
  margin-left: 20px;
}
.register {
  background: #3BCE5B;
  height: 100%;
  overflow: hidden;
}
.register >>> .van-field__control {
  color: #fff;
}
.seg {
  margin: 0 30px;
}
.subbox {
  margin: 80px 16px;
}
.sub {
  font-size: 17px;
}
.formt {
  margin-top: 50px;
  padding: 20px;
}
.yanzhengma {
  width: 60px;
  height: 100%;
}
.bar {
  overflow: hidden;
  margin-top: 20px;
}
.box {
  float: left;
  margin-left: 30px;
}
.user {
  float: right;
  font-size: 17px;
  margin-right: 30px;
  color: #fff;
}
</style>
